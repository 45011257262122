import { createBrowserRouter } from "react-router-dom";
// pages
// landing pages
import Index from "../Pages/landingPage/Index";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
    errorElement: <>error</>,
    children: [
      {
        path: "/",
        element: <Index />,
        errorElement: <>error</>,
      },
    ],
  },
]);

export default router;
