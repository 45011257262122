import React from "react";
import heroImage from "./images/dashboard-screenshot.png"; // Replace with the actual path to your image
import Maskgrouptopright from "./images/Maskgrouptopright.png";
import Maskgroupbottomleft from "./images/Maskgroupbottomleft.png";

const HeroSection = () => {
  return (
    <section className="bg-[#2F4238] text-white pt-32 sm:px-8 w-full relative">
      <img
        src={Maskgrouptopright}
        alt="Maskgrouptopright"
        className="absolute top-0 right-0 z-30 w-full"
      />
      <img
        src={Maskgroupbottomleft}
        alt="Maskgroupbottomleft"
        className="absolute bottom-0 left-0 z-30 w-full"
      />
      <div className="max-w-7xl mx-auto flex flex-col items-center">
        {/* Text Section */}
        <div className="md:w-1/2 mb-10 text-center">
          <h1 className="text-4xl md:text-5xl mb-6">
            We bring transparency to <br />
            SMBs with the use of AI
          </h1>
          <p className="text-sm mb-8">
            Harness the power of 25+ identity sources in a single API call
          </p>
        </div>
        <button className="bg-buttonColor text-white px-6 py-3 rounded-lg hover:bg-orange-600 transition-colors cursor-pointer z-30 mb-12">
          Get started for free
        </button>
        {/* Image Section */}
        <div className="w-[100%]">
          <img
            src={heroImage}
            alt="Dashboard Screenshot"
            className="rounded-lg shadow-lg w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
