import React from "react";
// import { Link } from "react-router-dom";
// import Drawer from "react-modern-drawer";
// import { HiMenu } from "react-icons/hi";
import "react-modern-drawer/dist/index.css";
import PannLogoYellow from "../../images/logo/PAAN Logo 1.png";

const Navbar = () => {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggleDrawer = () => {
  //   setIsOpen((prevState) => !prevState);
  // };

  // const scrollToSection = (sectionId) => {
  //   document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  //   setIsOpen(false); // Close drawer after navigation
  //   toggleDrawer();
  // };
  return (
    <nav className=" bg-[#2F4238] px-4 flex items-center justify-between fixed w-full z-[50]">
      {/* Logo */}
      <div className="flex items-center py-6">
        <img src={PannLogoYellow} alt="PannLogoYellow" />
      </div>

      {/* Desktop Links */}
      {/* <ul className="hidden md:flex space-x-6 text-white ml-32 p-2 rounded bg-[#2F4238] mb-4 pt-8 py-[15.2px] ">
        <li
          onClick={() => {
            scrollToSection("home");
          }}
        >
          <Link>Home</Link>
        </li>
        <li onClick={() => scrollToSection("faq")}>
          <Link>FAQs</Link>
        </li>
        <li>
          <Link onClick={() => scrollToSection("why")}>Why</Link>
        </li>
        <li>
          <Link onClick={() => scrollToSection("about")}>About Us</Link>
        </li>
      </ul> */}

      {/* Login / Create Account Buttons */}
      {/* <div className="hidden md:flex space-x-4">
        <button className="bg-white text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors">
          Login
        </button>
        <button className="bg-buttonColor text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition-colors">
          Create Free Account
        </button>
      </div> */}

      {/* Drawer for Mobile View */}
      {/* <div className="md:hidden">
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className="p-4 bg-[#2F4238] flex flex-col"
        >
          <div className="flex items-center justify-between mb-8">
            <img src={PannLogoYellow} alt="PannLogoYellow" />
            <button onClick={toggleDrawer} className="text-xl text-black">
              ✕
            </button>
          </div>

          <ul className="flex flex-col space-y-4 text-black p-4">
            <li>
              <Link
                onClick={() => {
                  scrollToSection("home");
                }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  scrollToSection("faq");
                }}
              >
                FAQs
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  scrollToSection("why");
                }}
              >
                Why
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  scrollToSection("about");
                }}
              >
                About Us
              </Link>
            </li>
          </ul>

          <div className="mt-8 flex flex-col space-y-4">
            <button className="bg-white text-gray-800 w-full py-2 rounded-full hover:bg-gray-200 transition-colors">
              Login
            </button>
            <button className="bg-buttonColor text-white w-full py-2 rounded-full hover:bg-orange-600 transition-colors z-30">
              Create Free Account
            </button>
          </div>
        </Drawer>
      </div> */}

      {/* Mobile Menu Button */}
      {/* <div className="md:hidden">
        <button onClick={toggleDrawer}>
          <HiMenu className="text-white w-6 h-6" />
        </button>
      </div> */}
    </nav>
  );
};

export default Navbar;
