import React from "react";
import socialGroup from "./images/Group 4.png";

const Footer = () => {
  return (
    <footer className="bg-[#214635] text-white relative pt-40">
      {/* Top CTA Section */}
      {/* <div className="bg-buttonColor py-10 px-8 text-center absolute w-[80%] rounded-3xl top-[-10%] left-[10%] transform -translate-x-{50%} -translate-y-[50%] md:-translate-y-1/2">
        <h2 className="text-3xl font-bold mb-4">Let's Get Started with Pann</h2>
        <p className="mb-6 sm:w-[70%] m-auto">
          Pann helps businesses streamline loan applications, organize client
          info, and track growth with efficient resource allocation and clear
          impact metrics.
        </p>
        <form className="flex justify-center items-center max-w-[350px] mx-auto relative">
          <input
            type="email"
            placeholder="Enter your email"
            className="p-2 w-2/3 rounded-l-md pr-20 text-black"
          />
          <div className="bg-white py-[2px] absolute right-16">
            <button
              type="submit"
              className="bg-green-900 text-white p-1 w-full rounded"
            >
              Sign Up
            </button>
          </div>
        </form>
        <p className="mt-2 text-sm text-gray-200">
          By subscribing, you agree to our{" "}
          <a href="#Privacy Policy" className="underline">
            Privacy Policy
          </a>
          .
        </p>
      </div> */}

      {/* Bottom Footer Section */}
      <div className="py-10 px-8">
        <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* About Section */}
          <div>
            <p className="text-sm text-gray-300">
              At Pann, we believe transparency is important. More transparency
              enables greater flow of credit and happier and healthier
              businesses.
            </p>
            <div className="py-4">
              <img src={socialGroup} alt="socialGroup" />
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className=" mb-4">Quick Links</h4>
            <ul className="text-sm text-gray-300 space-y-2">
              <li>
                <a href="#About" className="hover:underline">
                  About
                </a>
              </li>
              <li>
                <a href="#Features" className="hover:underline">
                  Features
                </a>
              </li>
              <li>
                <a href="#Works" className="hover:underline">
                  Works
                </a>
              </li>
              <li>
                <a href="#Career" className="hover:underline">
                  Career
                </a>
              </li>
            </ul>
          </div>

          {/* Help Section */}
          <div>
            <h4 className="mb-4">Help</h4>
            <ul className="text-sm text-gray-300 space-y-2">
              <li>
                <a href="#Customer Support" className="hover:underline">
                  Customer Support
                </a>
              </li>
              <li>
                <a href="#Delivery Details" className="hover:underline">
                  Delivery Details
                </a>
              </li>
              <li>
                <a href="#Terms & Conditions" className="hover:underline">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a href="# Privacy Policy" className="hover:underline">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>

          {/* Subscribe to Newsletter */}
          <div>
            <h4 className="font-semibold mb-4">Subscribe to Newsletter</h4>
            <form>
              <input
                type="email"
                placeholder="Enter your email"
                className="p-2 w-full rounded-t-md border border-gray-300 mb-2"
              />
              <button
                type="submit"
                className="bg-buttonColor text-white p-2 w-28"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="bg-[#1b332b] py-4 text-center text-sm text-gray-400">
        © Pann Capital Platform, Corp. All Rights Reserved
      </div>
    </footer>
  );
};

export default Footer;
