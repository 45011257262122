import React from "react";
import Navbar from "./Navbar";
import HeroSection from "./HeroSection/HeroSection";
// import CompaniesSection from "./CompaniesSection/CompaniesSection";
// import FeatureSection from "./FeatureSection/FeatureSection";
// import AboutSection from "./AboutSection/AboutSection";
// import PricingSection from "./PricingSection/Index";
// import TestimonialSection from "./TestimonialSection/TestimonialSection";
// import FAQSection from "./FAQSection";
import Footer from "./Footer/Footer";
// import CompaniesSection from "./CompaniesSection/CompaniesSection";

function LandingPage() {
  return (
    <div className="relative">
      <div className="absolute">
        <div className="bg-[#2F4238] w-full h-[80px] z-10 fixed"></div>
      </div>
      <Navbar />
      <div id="home">
        <HeroSection />
      </div>
      {/* <CompaniesSection /> */}
      {/* <div id="why">
        <FeatureSection />
      </div>
      <div id="about">
        <AboutSection />
      </div>
      <PricingSection />
      <TestimonialSection />
      <div id="faq">
        <FAQSection />
      </div> */}
      <Footer />
    </div>
  );
}

export default LandingPage;
